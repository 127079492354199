import React, { useState, useEffect } from "react";
import style from "./Footer.module.scss";
import { Link } from "react-router-dom"; // Importieren Sie Link von react-router-dom
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const pageHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY + window.innerHeight;

    if (scrollPosition >= pageHeight - 100) {
      // 100px vor dem Ende der Seite
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <footer
      className={`${style.footer} ${isVisible ? style.visible : style.hidden}`}
    >
      <span className={style.footerTxt}>{t("footer.rights")}</span>
      <div className={style.footerLinks}>
        <Link to="/impressum">{t("footer.terms")}</Link>
        <Link to="/privacy-policy">{t("footer.privacy")}</Link>
      </div>
    </footer>
  );
};

export default Footer;
