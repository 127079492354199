import { useTranslation } from "react-i18next";
import Heading from "../../modules/Heading/Heading";
import ServiceItem from "./SerivceItem/ServiceItem";
import style from "./Services.module.scss";
const Services = () => {
  const { t, i18n } = useTranslation();

  const serviceCategories = i18n.getResourceBundle(
    i18n.language,
    "translation"
  ).serviceCategories;

  return (
    <main className={style.services} id="services">
      <Heading heading={t("menuHeading.services")} />
      <div className={style.container}>
        <ServiceItem serviceCategories={serviceCategories} />
      </div>
    </main>
  );
};

export default Services;
