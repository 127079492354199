import React, { useState, useEffect, ReactNode } from "react";
import { ThreeDots } from "react-loader-spinner";

interface DelayedLoaderProps {
  children: ReactNode;
}

const DelayedLoader: React.FC<DelayedLoaderProps> = ({ children }) => {
  const [isDelayed, setIsDelayed] = useState(true);
  const [opacity, setOpacity] = useState(0); // Начальная прозрачность

  useEffect(() => {
    let fadeOutTimer: NodeJS.Timeout;
    const fadeOutDuration = 500; // Длительность исчезновения Loader
    const fadeInTimer = setTimeout(() => {
      setIsDelayed(false);
      fadeOutTimer = setTimeout(() => setOpacity(1), fadeOutDuration); // Постепенно показываем контент
    }, 2000); // Задержка перед началом анимации показа контента

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
    };
  }, []);

  if (isDelayed) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          opacity: 1 - opacity, // Плавное исчезновение Loader
          transition: `opacity ${500}ms ease`, // Плавный переход прозрачности
        }}
      >
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#FFFFFF"
          ariaLabel="three-dots-loading"
        />
      </div>
    );
  }

  return (
    <div
      style={{
        opacity,
        transition: "opacity 500ms ease", // Плавный переход прозрачности для контента
      }}
    >
      {children}
    </div>
  );
};

export default DelayedLoader;
