import { FC } from "react";
import { useTranslation } from "react-i18next";
import style from "./Navbar.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

type NavbarProps = {
  activeSection: string;
};

const Navbar: FC<NavbarProps> = ({ activeSection }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { menubar: "menu.home", idMenuBar: "Home" },
    { menubar: "menu.about", idMenuBar: "About" },
    { menubar: "menu.services", idMenuBar: "Services" },
    { menubar: "menu.skills", idMenuBar: "Skills" },
    { menubar: "menu.experience", idMenuBar: "Experience" },
    { menubar: "menu.contact", idMenuBar: "Contact" },
  ];

  const scrollToSection = (sectionId: string) => {
    const yOffset = -90;
    if (location.pathname === "/") {
      const section = document.getElementById(sectionId);
      if (section) {
        const y =
          section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {menuItems.map((category, index) => (
        <div key={index} className={style.navbar}>
          <li className={style.navItem}>
            <button
              onClick={() =>
                scrollToSection(category.idMenuBar.toLocaleLowerCase())
              }
              className={`${style.navLink} ${
                activeSection === category.idMenuBar.toLowerCase()
                  ? style.activeLink
                  : ""
              }`}
            >
              {t(category.menubar)}
            </button>
          </li>
        </div>
      ))}
    </>
  );
};

export default Navbar;
