import React, { ReactNode, useState, useEffect } from "react";
import style from "./Button.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Language } from "@styled-icons/fa-solid/Language"; // Stellen Sie sicher, dass dieser Import korrekt ist

type ButtonProps = {
  type: "cv" | "contact" | "back" | "language";
  children?: ReactNode;
};

const Button: React.FC<ButtonProps> = ({ children, type }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    setButtonText(
      i18n.language === "de" ? "English version" : "Deutsche Version"
    );
  }, [i18n.language]);

  const handleClick = () => {
    switch (type) {
      case "cv":
        window.open("/CV_AD.pdf", "_blank");
        break;
      case "contact":
        const contactSection = document.getElementById("contact");
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "back":
        navigate(-1);
        break;
      case "language":
        const newLang = i18n.language === "de" ? "en" : "de";
        i18n.changeLanguage(newLang);
        localStorage.setItem("language", newLang);
        break;
    }
  };

  return (
    <button
      className={`${style.btn} ${type === "language" ? style.languageBtn : ""}`}
      type="button"
      onClick={handleClick}
    >
      {type === "language" && (
        <>
          <Language
            size={20}
            style={{ marginRight: "10px", marginBottom: "3px" }}
          />
          {buttonText}
        </>
      )}
      {type !== "language" && children}
    </button>
  );
};

export default Button;
