export enum MenuHeading {
  ABOUT = "Über mich",
  SERVICES = "Meine Services",
  SKILLS = "Meine Kompetenzen",
  EXPERIENCE = "Erfahrung",
  CONTACT = "Kontakt",
}

export const colors = {
  bar: "hsl(256, 25%, 29%)",
  title: {
    text: "#abc123",
    background: "#225",
  },
};

export const navigation = [
  { menubar: "Home", idMenuBar: "Home" },
  { menubar: "Über mich", idMenuBar: "About" },
  { menubar: "Services", idMenuBar: "Services" },
  { menubar: "Kompetenzen", idMenuBar: "Skills" },
  { menubar: "Erfahrung", idMenuBar: "Experience" },
  { menubar: "Kontakt", idMenuBar: "Contact" },
];

export const frontendSkills = [
  { type: "JavaScript", level: 95 },
  { type: "TypeScript", level: 90 },
  { type: "Angular", level: 85 },
  { type: "React", level: 90 },
  { type: "NextJS", level: 90 },
  { type: "Vue", level: 80 },
];

export const backendSkills = [
  { type: "Java", level: 90 },
  { type: "Kotlin", level: 60 },
  { type: "NodeJS", level: 90 },
  { type: "NestJS", level: 90 },
  { type: "SpringBoot", level: 85 },
];

export const databaseSkills = [
  { type: "PostgreSQL", level: 95 },
  { type: "MongoDB", level: 85 },
  { type: "MySQL", level: 95 },
];

export const cloudSkills = [{ type: "AWS", level: 60 }];

export const mobileSkills = [
  { type: "Dart", level: 60 },
  { type: "Flutter", level: 60 },
];

export const testingSkills = [
  { type: "Jest", level: 70 },
  { type: "Playwright", level: 65 },
  { type: "JUnit", level: 80 },
];
