import { ReactNode, useState } from "react";
import style from "./Dropdown.module.scss";
import { ArrowheadDownOutline } from "@styled-icons/evaicons-outline/ArrowheadDownOutline";

type DropdownProps = {
  title: string;
  experience?: string;
  children: ReactNode;
};

const Dropdown: React.FC<DropdownProps> = ({ title, experience, children }) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleDropdown = () => setIsOpened(!isOpened);

  const rotateIcon = isOpened ? style.rotate : "rotate(3.142rad)";

  return (
    <div className={style.dropdown}>
      <div className={style.dropdownContainer} onClick={toggleDropdown}>
        <div className={style.dropdownTitle}>
          {title}
          <span className={style.experience}>{experience}</span>
        </div>

        <ArrowheadDownOutline
          size={20}
          className={style.dropdownIcon}
          style={{
            transform: rotateIcon,
            transition: "all 0.2s linear",
          }}
          onClick={toggleDropdown}
        />
      </div>
      {isOpened && <div className={style.dropdownContent}>{children}</div>}
    </div>
  );
};

export default Dropdown;
