import { FC, ReactElement } from "react";
import style from "./ServiceItem.module.scss";
import { Monitor } from "@styled-icons/material-rounded/Monitor";
import { Database } from "@styled-icons/entypo";
import { Codepen } from "@styled-icons/boxicons-logos";
import { CodeSlash } from "@styled-icons/bootstrap/CodeSlash";

type ServiceItemProps = {
  serviceCategories: { title: string; description: string }[];
};

type IconMapping = {
  [key: string]: () => ReactElement;
};

const iconMapping: IconMapping = {
  "Frontend Development": () => <Monitor size={70} color="#c3c3c3" />,
  "Backend Development": () => <Codepen size={70} color="#c3c3c3" />,
  "Database Development": () => <Database size={70} color="#c3c3c3" />,
  Beratung: () => <CodeSlash size={70} color="#c3c3c3" />,
};

const ServiceItem: FC<ServiceItemProps> = ({ serviceCategories }) => {
  return (
    <>
      {serviceCategories.map((category, index) => {
        const Icon =
          iconMapping[category.title] ||
          (() => <Monitor size={70} color="#c3c3c3" />);
        return (
          <div key={index} className={style.item}>
            <div className={style.card}>
              <div className={style.cardFront}>
                <Icon />
                <h1>{category.title}</h1>
              </div>
              <div className={style.cardBack}>
                <p>{category.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ServiceItem;
