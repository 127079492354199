import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importieren Sie Ihre Übersetzungsressourcen
import deTranslation from "./locales/de/translation.json";
import enTranslation from "./locales/en/translation.json";

const resources = {
  de: {
    translation: deTranslation,
  },
  en: {
    translation: enTranslation,
  },
} as const; // Verwenden Sie `as const` für vollständige Typsicherheit

i18n
  .use(initReactI18next) // Verbindet React mit i18next
  .init({
    resources,
    lng: "de", // Standard-/Fallback-Sprache
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // Escaped keine Werte
    },
    defaultNS: "translation", // Standard-Namespace
  });

export default i18n;
