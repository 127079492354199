import Heading from "../../modules/Heading/Heading";
import style from "./About.module.scss";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <main className={style.about} id="about">
      <Heading heading={t("menuHeading.about")} />
      <div className={style.container}>
        <div className={style.description}>
          <div className={style.title}>
            <h1>{t("about.title")}</h1>
          </div>
          <div className={style.txtContainer}>
            <p className={style.txt}>{t("about.firstParagraph")}</p>
            <p className={style.txt}>{t("about.secondParagraph")}</p>
            <p className={style.txt}>{t("about.thirdParagraph")}</p>
          </div>
        </div>
        <div className={style.context}>
          <div className={style.box}>{t("profile.projects")}</div>
          <div className={style.box}>{t("profile.experience")}</div>
          <div className={style.box}>{t("profile.apps")}</div>
          <div className={style.box}>{t("profile.certifications")}</div>
        </div>
      </div>
    </main>
  );
};

export default About;
