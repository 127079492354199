import React, { ReactNode } from "react";
import { Email } from "@styled-icons/entypo";
import { Telegram, Whatsapp } from "@styled-icons/fa-brands";
import { Linkedin } from "@styled-icons/fa-brands/Linkedin";
import style from "./SocialButton.module.scss";
import { Freelancer } from "@styled-icons/simple-icons/Freelancer";

type IconMapping = {
  [key: string]: ReactNode;
};

const socialIconMapping: IconMapping = {
  Email: <Email size={30} />,
  Linkedin: <Linkedin size={30} style={{ paddingLeft: "5px" }} />,
  Whatsapp: <Whatsapp size={30} style={{ paddingLeft: "5px" }} />,
  Telegram: <Telegram size={30} style={{ paddingLeft: "5px" }} />,
  Freelancer: <Freelancer size={30} style={{ paddingLeft: "5px" }} />,
};

type SocialButtonType = {
  icon: string;
  url: string;
  title: string;
};

const SocialButton = ({ icon, url, title }: SocialButtonType) => {
  const iconElement = socialIconMapping[icon] || null;

  return (
    <a
      href={url}
      className={style.socialBtnLink}
      data-cursor="-hidden invisible"
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      {iconElement}
      <span className={style.socialBtnTitle}>{title}</span>
    </a>
  );
};

export default SocialButton;
