import { useEffect } from "react";
import style from "../Privacy.module.scss";

const PrivacyDE = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.privacyContainer}>
      <>
        <ul className={style.breadcrumb}>
          <li>
            <a href="/">Startseite</a>
          </li>
          <li>Datenschutz</li>
        </ul>
        <h1>Datenschutzerklärung</h1>
        <h2 id="m716">Präambel</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.&nbsp;B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 16. März 2024</p>
        <h2>Inhaltsübersicht</h2>{" "}
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Präambel
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Übermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              Internationale Datentransfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Löschung von Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Bereitstellung des Onlineangebotes und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m134">
              Einsatz von Cookies
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Präsenzen in sozialen Netzwerken (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Änderung und Aktualisierung der Datenschutzerklärung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          Andrei Dzilanian
          <br />
          Erlenhof, 41
          <br />
          14478, Potsdam, Brandenburg, Deutschland
        </p>
        <p>
          E-Mail-Adresse: {""}
          <a href="mailto:andrei.dzilanian@gmail.com" rel="noreferrer noopener">
            andrei.dzilanian@gmail.com
          </a>
        </p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Verwaltung und Beantwortung von Anfragen.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>Informationstechnische Infrastruktur.</li>
        </ul>
        <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
        <p>
          <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
          Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
          auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen
          Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
          Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
          Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
          das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Ferner können
          Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
          gelangen.
        </p>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die über
          unsere Online-Dienste übertragen werden, zu schützen, verwenden wir
          TLS/SSL-Verschlüsselung. Secure Sockets Layer (SSL) ist die
          Standardtechnologie zur Sicherung von Internetverbindungen durch
          Verschlüsselung der zwischen einer Website oder App und einem Browser
          (oder zwischen zwei Servern) übertragenen Daten. Transport Layer
          Security (TLS) ist eine aktualisierte und sicherere Version von SSL.
          Hyper Text Transfer Protocol Secure (HTTPS) wird in der URL angezeigt,
          wenn eine Website durch ein SSL/TLS-Zertifikat gesichert ist.
        </p>
        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
          selbstständige Organisationseinheiten oder Personen übermittelt oder
          sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten
          können z.&nbsp;B. mit IT-Aufgaben beauftragte Dienstleister oder
          Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden
          werden, gehören. In solchen Fällen beachten wir die gesetzlichen
          Vorgaben und schließen insbesondere entsprechende Verträge bzw.
          Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern
          Ihrer Daten ab.
        </p>
        <h2 id="m24">Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
          (d.&nbsp;h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen
          der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
          Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
          stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
          Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines
          Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient
          dieser als Grundlage des Datentransfers. Im Übrigen erfolgen
          Datentransfers nur dann, wenn das Datenschutzniveau anderweitig
          gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46
          Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall
          vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49
          Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der
          Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland
          mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig
          gelten. Informationen zu Drittlandtransfers und vorliegenden
          Angemessenheitsbeschlüssen können dem Informationsangebot der
          EU-Kommission entnommen werden:{" "}
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
          </a>
        </p>
        <p>
          EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
          „Data Privacy Framework" (DPF) hat die EU-Kommission das
          Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
          Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
          anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
          Informationen zu dem DPF können Sie der Webseite des
          Handelsministeriums der USA unter{" "}
          <a
            href="https://www.dataprivacyframework.gov/"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://www.dataprivacyframework.gov/
          </a>{" "}
          (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
          Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter
          dem Data Privacy Framework zertifiziert sind.
        </p>
        <h2 id="m12">Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z.&nbsp;B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist
          oder sie für den Zweck nicht erforderlich sind). Sofern die Daten
          nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
          Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke
          beschränkt. D.&nbsp;h., die Daten werden gesperrt und nicht für andere
          Zwecke verarbeitet. Das gilt z.&nbsp;B. für Daten, die aus handels-
          oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
          Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist. Unsere Datenschutzhinweise
          können ferner weitere Angaben zu der Aufbewahrung und Löschung von
          Daten beinhalten, die für die jeweiligen Verarbeitungen vorrangig
          gelten.
        </p>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
          Betroffene nach der DSGVO verschiedene Rechte zu, die sich
          insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
            werden und auf Auskunft über diese Daten sowie auf weitere
            Informationen und Kopie der Daten entsprechend den gesetzlichen
            Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong>
              Recht auf Löschung und Einschränkung der Verarbeitung:
            </strong>{" "}
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
            bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
            Einschränkung der Verarbeitung der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
            einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
            unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
            mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
          zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
          IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
          Funktionen unserer Online-Dienste an den Browser oder das Endgerät der
          Nutzer zu übermitteln.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.&nbsp;B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-,
            Kommunikations- und Verfahrensdaten (z.&nbsp;B. IP-Adressen,
            Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
            und technischen Geräten (Computer, Server etc.).).
            Sicherheitsmaßnahmen.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
            </strong>
            Für die Bereitstellung unseres Onlineangebotes nutzen wir
            Speicherplatz, Rechenkapazität und Software, die wir von einem
            entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder
            anderweitig beziehen;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
            Zugriff auf unser Onlineangebot wird in Form von so genannten
            "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
            Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
            Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
            erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
            des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
            Regelfall IP-Adressen und der anfragende Provider gehören. Die
            Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
            werden, z.&nbsp;B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken) und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).{" "}
            </span>
            <strong>Löschung von Daten:</strong> Logfile-Informationen werden
            für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht
            oder anonymisiert. Daten, deren weitere Aufbewahrung zu
            Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
            jeweiligen Vorfalls von der Löschung ausgenommen.
          </li>
        </ul>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>
          Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
          Informationen auf Endgeräten speichern und Informationen aus den
          Endgeräten auslesen. Z.&nbsp;B. um den Login-Status in einem
          Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen
          Inhalte oder verwendete Funktionen eines Onlineangebotes speichern.
          Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden,
          z.&nbsp;B. zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort
          von Onlineangeboten sowie der Erstellung von Analysen der
          Besucherströme.{" "}
        </p>
        <p>
          <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im
          Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von
          den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese
          gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere
          nicht notwendig, wenn das Speichern und das Auslesen der
          Informationen, also auch von Cookies, unbedingt erforderlich sind, um
          dem den Nutzern einen von ihnen ausdrücklich gewünschten
          Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen.
          Zu den unbedingt erforderlichen Cookies gehören in der Regel Cookies
          mit Funktionen, die der Anzeige und Lauffähigkeit des Onlineangebotes
          , dem Lastausgleich, der Sicherheit, der Speicherung der Präferenzen
          und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
          Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
          angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
          widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
          kommuniziert und enthält die Informationen zu der jeweiligen
          Cookie-Nutzung.
        </p>
        <p>
          <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>
          Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
          personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten,
          hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die
          Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer
          Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von
          Cookies verarbeiteten Daten auf Grundlage unserer berechtigten
          Interessen (z.&nbsp;B. an einem betriebswirtschaftlichen Betrieb
          unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit)
          verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer
          vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
          erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
          Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber
          klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von
          unseren Einwilligungs- und Verarbeitungsprozessen auf.
        </p>
        <p>
          <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
          werden die folgenden Arten von Cookies unterschieden:
        </p>
        <ul>
          <li>
            <strong>
              Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
            </strong>
            &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein
            Nutzer ein Online-Angebot verlassen und sein Endgerät (z.&nbsp;B.
            Browser oder mobile Applikation) geschlossen hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
            nach dem Schließen des Endgerätes gespeichert. So können
            beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
            direkt angezeigt werden, wenn der Nutzer eine Website erneut
            besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der
            Nutzer zur Reichweitenmessung verwendet werden. Sofern wir
            Nutzern&nbsp;keine expliziten Angaben zur Art und Speicherdauer von
            Cookies mitteilen (z.&nbsp;B. im Rahmen der Einholung der
            Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent
            sind und die Speicherdauer bis zu zwei Jahre betragen kann.
          </li>
        </ul>
        <p>
          <strong>
            Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):{" "}
          </strong>
          Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
          widerrufen und der Verarbeitung entsprechend den gesetzlichen Vorgaben
          widersprechen. Hierzu können Nutzer unter anderem die Verwendung von
          Cookies in den Einstellungen ihres Browsers einschränken (wobei
          dadurch auch die Funktionalität unseres Onlineangebotes eingeschränkt
          sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
          Online-Marketing-Zwecken kann auch über die Websites{" "}
          <a href="https://optout.aboutads.info/" target="_new">
            https://optout.aboutads.info
          </a>{" "}
          und{" "}
          <a href="https://www.youronlinechoices.com/" target="_new">
            https://www.youronlinechoices.com/
          </a>{" "}
          erklärt werden.
        </p>
        <ul className="m-elements">
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
            DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
            </strong>
            Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die
            Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
            Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
            Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
            Protokollierung, Verwaltung und dem Widerruf von Einwilligungen,
            insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren
            Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung
            von Informationen auf den Endgeräten der Nutzer eingesetzt werden.
            Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für
            die Nutzung von Cookies und die damit verbundenen Verarbeitungen von
            Informationen, einschließlich der im
            Einwilligungs-Management-Verfahren genannten spezifischen
            Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die
            Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die
            Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage
            zu vermeiden und den Nachweis der Einwilligung gemäß der
            gesetzlichen Anforderungen führen zu können. Die Speicherung erfolgt
            serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie)
            oder mittels vergleichbarer Technologien, um die Einwilligung einem
            spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern
            keine spezifischen Angaben zu den Anbietern von
            Einwilligungs-Management-Diensten vorliegen, gelten folgende
            allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung
            beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer
            Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der
            Einwilligung, den Angaben zum Umfang der Einwilligung (z.&nbsp;B.
            betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie
            Informationen über den Browser, das System und das verwendete
            Endgerät gespeichert wird;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
          Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
          Raumes der Europäischen Union verarbeitet werden können. Hierdurch
          können sich für die Nutzer Risiken ergeben, weil so z.&nbsp;B. die
          Durchsetzung der Rechte der Nutzer erschwert werden könnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          z.&nbsp;B. anhand des Nutzungsverhaltens und sich daraus ergebender
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z.&nbsp;B.
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
          werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
          in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
          sind und bei diesen eingeloggt sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und können direkt
          entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
          dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.&nbsp;B.
            E-Mail, Telefonnummern); Inhaltsdaten (z.&nbsp;B. Eingaben in
            Onlineformularen); Nutzungsdaten (z.&nbsp;B. besuchte Webseiten,
            Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und
            Verfahrensdaten (z.&nbsp;B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, Einwilligungsstatus).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation; Feedback (z.&nbsp;B. Sammeln von Feedback via
            Online-Formular). Marketing.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>LinkedIn: </strong>Soziales Netzwerk;{" "}
            <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company,
            Wilton Place, Dublin 2, Irland;{" "}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>{" "}
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF); <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            . <strong>Weitere Informationen:</strong> Wir sind gemeinsam mit
            LinkedIn Irland Unlimited Company für die Erhebung (jedoch nicht die
            weitere Verarbeitung) von Daten der Besucher, die zu Zwecken der
            Erstellung der „Page-Insights" (Statistiken) unserer
            LinkedIn-Profile verantwortlich. <br />
            Zu diesen Daten gehören Informationen zu den Arten von Inhalten, die
            Nutzer sich ansehen oder mit denen sie interagieren, oder die von
            ihnen vorgenommenen Handlungen sowie Informationen über die von den
            Nutzern genutzten Geräte (z.&nbsp;B. IP-Adressen, Betriebssystem,
            Browsertyp, Spracheinstellungen, Cookie-Daten) und Angaben aus dem
            Profil der Nutzer, wie Berufsfunktion, Land, Branche,
            Hierarchieebene, Unternehmensgröße und Beschäftigungsstatus.
            Datenschutzinformationen zur Verarbeitung von Daten der Nutzer durch
            LinkedIn können den Datenschutzhinweisen von LinkedIn entnommen
            werden:{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>{" "}
            <br />
            Wir haben mit LinkedIn Irland eine spezielle Vereinbarung
            abgeschlossen ("Page Insights Joint Controller Addendum (the
            ‚Addendum‘)",{" "}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
            LinkedIn beachten muss und in der LinkedIn sich bereit erklärt hat
            die Betroffenenrechte zu erfüllen (d. h. Nutzer können z.&nbsp;B.
            Auskünfte oder Löschungsanfragen direkt an LinkedIn richten). Die
            Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch
            und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
            Vereinbarungen mit LinkedIn nicht eingeschränkt. Die gemeinsame
            Verantwortlichkeit beschränkt sich auf die Erhebung der Daten durch
            und die Übermittlung an die Ireland Unlimited Company, ein
            Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten
            obliegt ausschließlich der Ireland Unlimited Company, was
            insbesondere die Übermittlung der Daten an die Muttergesellschaft
            LinkedIn Corporation in den USA betrifft.
          </li>
        </ul>
        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.&nbsp;B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
          Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
          Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem
          dem Verständnis dienen.
        </p>
        <ul className="glossary">
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten"
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person (im Folgenden "betroffene
            Person") beziehen; als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z.&nbsp;B. Cookie) oder zu
            einem oder mehreren besonderen Merkmalen identifiziert werden kann,
            die Ausdruck der physischen, physiologischen, genetischen,
            psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
            dieser natürlichen Person sind.{" "}
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die
            natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
            bezeichnet.{" "}
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
            Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.{" "}
          </li>
        </ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noreferrer noopener"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
      </>
    </div>
  );
};

export default PrivacyDE;
