import { FC } from "react";
import style from "./Heading.module.scss";
import { MenuHeading } from "../../utils/skills";

type HeadingProps = {
  heading: MenuHeading;
};

const Heading: FC<HeadingProps> = ({ heading }) => {
  return (
    <>
      <div className={style.heading}>{heading}</div>
    </>
  );
};

export default Heading;
