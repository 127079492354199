import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PrivacyDE from "./PrivacyDE/PrivacyDE";
import PrivacyEN from "./PrivacyEN/PrivacyEN";

const Privacy = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    // Aktualisiere den aktuellen Sprachzustand, wenn die Sprache geändert wird
    const handleLangChange = () => {
      setCurrentLang(i18n.language);
    };

    i18n.on("languageChanged", handleLangChange);

    // Cleanup-Funktion, um den Event Listener zu entfernen
    return () => {
      i18n.off("languageChanged", handleLangChange);
    };
  }, [i18n]);

  return (
    <>
      {currentLang === "de" && <PrivacyDE />}
      {currentLang === "en" && <PrivacyEN />}
    </>
  );
};

export default Privacy;
