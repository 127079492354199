import Heading from "../../modules/Heading/Heading";
import style from "./Contact.module.scss";
import SocialButton from "../../modules/SocialButton/SocialButton";
import { useTranslation } from "react-i18next";

const socialLinks = [
  {
    icon: "Email",
    url: "mailto:andrei.dzilanian@gmail.com",
    title: "Email",
  },
  {
    icon: "Freelancer",
    url: "https://www.freelancermap.de/profil/senior-frontend-entwickler-241785-65400d6e673752.62238940",
    title: "Freelancermap",
  },
  {
    icon: "Linkedin",
    url: "https://linkedin.com/in/andrei-dzilanian-aa84561b6",
    title: "LinkedIn",
  },
  {
    icon: "Whatsapp",
    url: "https://api.whatsapp.com/send/?phone=491727714074&text=Hi%21+Lass%27s+uns+in+Verbindung+bleiben+&type=phone_number&app_absent=0",
    title: "WhatsApp",
  },
  {
    icon: "Telegram",
    url: "https://t.me/andrej_935",
    title: "Telegram",
  },
];

const Contact = () => {
  const { t } = useTranslation();
  return (
    <main className={style.contact} id="contact">
      <Heading heading={t("menuHeading.contact")} />
      <div className={style.container}>
        <p className={style.title}>{t("contact.title")}</p>
        <ul className={style.contactOptions}>
          <div className={style.socialMedia}>
            {socialLinks.map(({ icon, url, title }, index) => (
              <SocialButton key={index} icon={icon} url={url} title={title} />
            ))}
          </div>
        </ul>
        <p className={style.responseTime}>{t("contact.subTitle")}</p>
      </div>
    </main>
  );
};

export default Contact;
