import { TypeAnimation } from "react-type-animation";
import Button from "../../modules/Button/Button";
import style from "./Home.module.scss";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <main className={style.home} id="home">
      <div className={style.homeContainer}>
        <div className={style.description}>
          <p className={style.name}>{t("home.name")}</p>
          <TypeAnimation
            className={style.job}
            sequence={[t("home.position"), 3000, ""]}
            speed={50}
            style={{ whiteSpace: "pre-line", fontSize: "2.7em" }}
            repeat={Infinity}
          />
          <div className={style.buttons}>
            <Button type="contact">{t("home.talkToUs")}</Button>
          </div>
        </div>
        <div className={style.photo}>
          <img src="me1.jpg" alt="" />
        </div>
      </div>
    </main>
  );
};

export default Home;
