import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ISourceOptions } from "@tsparticles/engine";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { particlesOptions } from "./utils/particlesConfig";
import "./App.css";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Experience from "./components/Experience/Experience";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Skills from "./components/Skills/Skills";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import DelayedLoader from "./modules/DelayedLoader/DelayedLoader"; // Stelle sicher, dass der Pfad korrekt ist
import Privacy from "./components/Privacy/Privacy";
import "./i18n";
import { useTranslation } from "react-i18next";
const Impressum = lazy(() => import("./components/Impressum/Impressum"));

function App() {
  const [init, setInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      // Simuliere eine zusätzliche Ladezeit
      setTimeout(() => {
        setInit(true);
        setIsLoading(false);
      }, 1000); // 3 Sekunden zusätzlich, nachdem Particles geladen sind
    });
  }, []);

  const options: ISourceOptions = useMemo(() => particlesOptions, []);

  if (isLoading) {
    return (
      <DelayedLoader>
        <div />
      </DelayedLoader>
    ); // Zeige den Loader beim initialen Laden
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="contentContainer">
                <Home />
                <About />
                <Services />
                <Skills />
                <Experience />
                <Contact />
              </div>
              {init && <Particles id="tsparticles" options={options} />}
            </>
          }
        />
        <Route
          path="/impressum"
          element={
            <Suspense
              fallback={
                <DelayedLoader>
                  <div />
                </DelayedLoader>
              }
            >
              <DelayedLoader>
                <Impressum />
              </DelayedLoader>
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense
              fallback={
                <DelayedLoader>
                  <div />
                </DelayedLoader>
              }
            >
              <DelayedLoader>
                <Privacy />
              </DelayedLoader>
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
