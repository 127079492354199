import style from "./Skills.module.scss";
import SkillBar from "react-skillbars";
import Dropdown from "../../modules/Dropdown/Dropdown";
import {
  colors,
  frontendSkills,
  backendSkills,
  databaseSkills,
} from "../../utils/skills";
import Heading from "../../modules/Heading/Heading";
import { useTranslation } from "react-i18next";
// Swiper imports
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const Skills = () => {
  const { t } = useTranslation();

  const localizedSkillsCategories: { title: string; experience: string }[] = t(
    "skillsCategories",
    { returnObjects: true }
  );

  const skillsData = [
    { ...localizedSkillsCategories[0], skills: frontendSkills },
    { ...localizedSkillsCategories[1], skills: backendSkills },
    { ...localizedSkillsCategories[2], skills: databaseSkills },
  ];

  return (
    <main className={style.skills} id="skills">
      <Heading heading={t("menuHeading.skills")} />
      <div className={style.dropdowns}>
        {skillsData.map((category, index) => (
          <Dropdown
            key={index}
            title={category.title}
            experience={category.experience}
          >
            <SkillBar skills={category.skills} height={20} colors={colors} />
          </Dropdown>
        ))}
      </div>
      <div className={style.slider}>
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={30}
          slidesPerView={5}
          slidesPerGroup={1}
          speed={500}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          navigation={true}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
        >
          <SwiperSlide>
            <img src={`/angular.png`} alt="Angular" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/react.png`} alt="React" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/vue.png`} alt="Vue" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/next.png`} alt="Next.js" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/nuxtjs.png`} alt="NuxtJS" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/spring.png`} alt="Spring" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/nodejs.png`} alt="Node.js" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/nestjs.png`} alt="NestJS" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/javascript.png`} alt="JavaScript" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/typescript.png`} alt="TypeScript" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/java.png`} alt="Java" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/docker.png`} alt="Docker" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/k8s.png`} alt="Kubernetes" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/aws.png`} alt="AWS" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/azure.png`} alt="Azure" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/html.png`} alt="HTML" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/css.png`} alt="CSS" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/postgresql.png`} alt="PostgreSQL" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/mongodb.png`} alt="MongoDB" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/gitlab.png`} alt="GitLab" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/github.png`} alt="GitHub" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/swagger.png`} alt="Swagger" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={`/graphql.png`} alt="GraphQL" />
          </SwiperSlide>
        </Swiper>
      </div>
    </main>
  );
};

export default Skills;
