import { useEffect } from "react";
import style from "../Privacy.module.scss";

const PrivacyEN = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.privacyContainer}>
      <>
        <ul className={style.breadcrumb}>
          <li>
            <a href="/">Home</a>
          </li>
          <li>Privacy Policy</li>
        </ul>
        <h1>Privacy Policy</h1>
        <h2 id="m716">Preamble</h2>
        <p>
          With the following privacy policy, we would like to inform you about
          the types of your personal data (hereinafter also abbreviated as
          "data") we process, for what purposes and to what extent. This privacy
          policy applies to all the processing of personal data carried out by
          us, both in the context of providing our services and, in particular,
          on our websites, in mobile applications, and within external online
          presences, such as our social media profiles (collectively referred to
          as "online offer").
        </p>
        <p>The terms used are not gender-specific.</p>
        <p>Date: March 16, 2024</p>
        <h2>Table of Contents</h2>
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Preamble
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Controller
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Overview of Processing
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Relevant Legal Bases
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Security Measures
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Transmission of Personal Data
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              International Data Transfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Deletion of Data
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rights of the Data Subjects
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Provision of the Online Offer and Web Hosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m134">
              Use of Cookies
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Presence in Social Networks (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Modification and Update of the Privacy Policy
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Definitions
            </a>
          </li>
        </ul>
        <h2 id="m3">Controller</h2>
        <p>
          Andrei Dzilanian
          <br />
          Erlenhof, 41
          <br />
          14478 Potsdam, Brandenburg, Germany
        </p>
        <p>
          Email Address:{" "}
          <a href="mailto:andrei.dzilanian@gmail.com" rel="noreferrer noopener">
            andrei.dzilanian@gmail.com
          </a>
        </p>
        <h2 id="mOverview">Overview of Processing</h2>
        <p>
          The following overview summarizes the types of data processed and the
          purposes of their processing and refers to the data subjects.
        </p>
        <h3>Types of Processed Data</h3>
        <ul>
          <li>Contact Data.</li>
          <li>Content Data.</li>
          <li>Usage Data.</li>
          <li>Meta/Communication Data.</li>
        </ul>
        <h3>Categories of Data Subjects</h3>
        <ul>
          <li>Communication Partners.</li>
          <li>Users.</li>
        </ul>
        <h3>Purposes of Processing</h3>
        <ul>
          <li>Contact requests and communication.</li>
          <li>Security measures.</li>
          <li>Administration and response to inquiries.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Provision of our online offer and user-friendliness.</li>
          <li>Information technology infrastructure.</li>
        </ul>
        <h2 id="m2427">Relevant Legal Bases</h2>
        <p>
          <strong>Relevant legal bases according to the GDPR:</strong> Below,
          you will find an overview of the legal bases of the GDPR on which we
          base the processing of personal data. Please note that in addition to
          the provisions of the GDPR, national data protection provisions may
          apply in your or our country of residence or domicile. Furthermore, if
          more specific legal bases are applicable in individual cases, we will
          inform you about them in the privacy policy.
        </p>
        <ul>
          <li>
            <strong>Consent (Article 6 (1) (a) GDPR)</strong> - The data subject
            has given consent to the processing of their personal data for one
            or more specific purposes.
          </li>
          <li>
            <strong>Legitimate Interests (Article 6 (1) (f) GDPR)</strong> -
            Processing is necessary for the purposes of the legitimate interests
            pursued by the controller or by a third party, except where such
            interests are overridden by the interests or fundamental rights and
            freedoms of the data subject which require protection of personal
            data.
          </li>
        </ul>
        <p>
          <strong>National data protection regulations in Germany:</strong> In
          addition to the data protection regulations of the GDPR, national
          regulations on data protection apply in Germany. These include, in
          particular, the Law on the Protection against Misuse of Personal Data
          in Data Processing (Federal Data Protection Act – BDSG). The BDSG
          contains special regulations on the right to access, the right to
          delete, the right to object, the processing of special categories of
          personal data, processing for other purposes and transmission as well
          as automated decision-making in individual cases including profiling.
          Furthermore, state data protection laws of the individual federal
          states may apply.
        </p>
        <h2 id="m27">Security Measures</h2>
        <p>
          We take appropriate technical and organizational measures in
          accordance with the legal requirements, taking into account the state
          of the art, the costs of implementation, and the nature, scope,
          context, and purposes of processing as well as the varying likelihood
          and severity of the risk to the rights and freedoms of natural
          persons, to ensure a level of security appropriate to the risk.
        </p>
        <p>
          The measures include, in particular, securing the confidentiality,
          integrity, and availability of data by controlling physical and
          electronic access to the data as well as access, input, transmission,
          ensuring availability, and its separation. In addition, we have
          established procedures to ensure the exercise of data subjects'
          rights, deletion of data, and reaction to data risks. Furthermore, we
          consider the protection of personal data already in the development or
          selection of hardware, software, and procedures, according to the
          principle of privacy by design and privacy by default.
        </p>
        <p>
          TLS/SSL encryption (https): To protect the data transmitted via our
          online service, we use TLS/SSL encryption. Secure Sockets Layer (SSL)
          is the standard technology for securing internet connections and
          encrypting data transferred between a website or app and a browser (or
          between two servers). Transport Layer Security (TLS) is an updated,
          more secure version of SSL. Hyper Text Transfer Protocol Secure
          (HTTPS) appears in the URL when a website is secured by an SSL/TLS
          certificate.
        </p>
        <h2 id="m25">Transmission of Personal Data</h2>
        <p>
          In the course of our processing of personal data, it may happen that
          the data is transferred to other places, companies, legally
          independent organizational units, or persons. The recipients of this
          data may include, for example, service providers commissioned with IT
          tasks or providers of services and content that are embedded in a
          website. In such cases, we comply with the legal requirements and, in
          particular, conclude appropriate contracts or agreements, which serve
          to protect your data, with the recipients of your data.
        </p>
        <h2 id="m24">International Data Transfers</h2>
        <p>
          Data processing in third countries: If we process data in a third
          country (i.e., outside the European Union (EU), the European Economic
          Area (EEA)) or if the processing takes place in the context of the use
          of third-party services or disclosure or transmission of data to other
          persons, bodies, or companies, this is only done in accordance with
          the legal requirements. If the level of data protection in the third
          country has been recognized by means of an adequacy decision (Article
          45 GDPR), this serves as the basis for the data transfer. Otherwise,
          data transfers only take place if the level of data protection is
          otherwise secured, in particular through standard contractual clauses
          (Article 46 (2) (c) GDPR), express consent, or in the case of
          contractual or legally required transmission (Article 49 (1) GDPR).
          Furthermore, we inform you about the basis of the third-country
          transfer with the individual providers from the third country, whereby
          the adequacy decisions serve as the primary basis. Information on
          third-country transfers and the existing adequacy decisions can be
          obtained from the information offer of the EU Commission:
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
          </a>
        </p>
        <p>
          EU-US Trans-Atlantic Data Privacy Framework: In the context of the
          so-called "Data Privacy Framework" (DPF), the EU Commission has also
          recognized the level of data protection for certain companies in the
          USA as safe within the framework of the adequacy decision of July 10,
          2023. The list of certified companies and further information on the
          DPF can be found on the website of the US Department of Commerce at
          <a
            href="https://www.dataprivacyframework.gov/"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://www.dataprivacyframework.gov/
          </a>{" "}
          (in English). We inform you in the context of the privacy notices
          which of our service providers are certified under the Data Privacy
          Framework.
        </p>
        <h2 id="m12">Deletion of Data</h2>
        <p>
          The data we process will be deleted in accordance with legal
          requirements as soon as their consent for processing is revoked or
          other permissions no longer apply (e.g., if the purpose of processing
          this data has lapsed or it is not required for the purpose). If the
          data is not deleted because it is required for other and legally
          permissible purposes, its processing is limited to these purposes.
          This means that the data is blocked and not processed for other
          purposes. This applies, for example, to data that must be retained for
          commercial or tax law reasons or whose storage is necessary for the
          assertion, exercise, or defense of legal claims or to protect the
          rights of another natural or legal person. Our privacy notices may
          also contain further information on the retention and deletion of
          data, which prevail for the respective processing operations.
        </p>
        <h2 id="m10">Rights of the Data Subjects</h2>
        <p>
          Rights of the data subjects according to the GDPR: You, as the data
          subjects, have various rights under the GDPR, which arise in
          particular from Articles 15 to 21 GDPR:
        </p>
        <ul>
          <li>
            <strong>
              Right to Object: You have the right to object at any time, on
              grounds relating to your particular situation, to the processing
              of your personal data which is based on Article 6 (1) (e) or (f)
              GDPR, including profiling based on those provisions. If the
              personal data concerning you is processed for direct marketing
              purposes, you have the right to object at any time to the
              processing of the personal data concerning you for such marketing;
              this also applies to profiling to the extent that it is related to
              such direct marketing.
            </strong>
          </li>
          <li>
            <strong>Right to Withdraw Consents:</strong> You have the right to
            withdraw consent at any time.
          </li>
          <li>
            <strong>Right of Access:</strong> You have the right to request
            confirmation as to whether the data in question is being processed
            and to be informed of this data and to receive further information
            and a copy of the data in accordance with legal requirements.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You have the right, in
            accordance with legal requirements, to request the completion of the
            data concerning you or the correction of the incorrect data
            concerning you.
          </li>
          <li>
            <strong>Right to Erasure and Restriction of Processing:</strong> In
            accordance with legal requirements, you have the right to demand
            that relevant data be erased immediately, or, alternatively, to
            demand a restriction of the processing of the data in accordance
            with legal requirements.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You have the right to
            receive data concerning you, which you have provided to us, in a
            structured, common, and machine-readable format in accordance with
            legal requirements, or to request its transmission to another
            controller.
          </li>
          <li>
            <strong>Complaint to Supervisory Authority:</strong> Regardless of
            any other administrative or judicial remedy, you have the right to
            lodge a complaint with a supervisory authority, in particular in the
            Member State of your habitual residence, place of work, or place of
            the alleged infringement if you consider that the processing of
            personal data relating to you infringes the GDPR.
          </li>
        </ul>
        <h2 id="m225">Provision of the Online Offer and Web Hosting</h2>
        <p>
          We process the data of users to be able to offer them our online
          services. For this purpose, we process the IP address of the user,
          which is necessary to transmit the contents and functions of our
          online services to the browser or the end device of the users.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Types of Data Processed:</strong> Usage data (e.g., visited
            websites, interest in content, access times). Meta/communication
            data (e.g., IP addresses, time stamps, identification numbers,
            consent status).
          </li>
          <li>
            <strong>Affected Persons:</strong> Users (e.g., website visitors,
            users of online services).
          </li>
          <li>
            <strong>Purposes of Processing:</strong> Provision of our online
            offer and user-friendliness; Information technology infrastructure
            (operation and provision of information systems and technical
            devices (computers, servers, etc.)). Security measures.
          </li>
          <li className="">
            <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further Information on Processing Processes, Procedures, and
            Services:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Provision of Online Offer on Rented Storage Space:</strong>
            For the provision of our online offer, we use storage space,
            computing capacity, and software that we rent or obtain otherwise
            from a corresponding server provider (also known as "web hoster");
            <span className="">
              <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Collection of Access Data and Log Files: </strong>The access
            to our online offer is logged in the form of so-called "server log
            files". The server log files can include the address and name of the
            accessed websites and files, date and time of access, transferred
            data volumes, notification of successful access, browser type
            together with version, the user's operating system, referrer URL
            (the previously visited page), and generally IP addresses and the
            requesting provider. The server log files can be used for security
            purposes, e.g., to avoid overloading the servers (especially in the
            case of abusive attacks, so-called DDoS attacks) and to ensure the
            load of the servers and their stability;
            <span className="">
              <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
            <strong>Data Deletion:</strong> Log file information is stored for a
            maximum of 30 days and then deleted or anonymized. Data, the further
            retention of which is required for evidence purposes, is exempted
            from deletion until the final clarification of the respective
            incident.
          </li>
        </ul>
        <h2 id="m134">Use of Cookies</h2>
        <p>
          Cookies are small text files or other storage notes that store
          information on end devices and read information from the end devices.
          For example, to save the login status in a user account, a shopping
          cart content in an e-shop, the called contents or used functions of an
          online offer. Cookies can also be used for different purposes, e.g.,
          for the purposes of functionality, security, and comfort of online
          offers as well as the creation of analyses of visitor flows.
        </p>
        <p>
          <strong>Notes on Consent: </strong>We use cookies in accordance with
          legal regulations. Therefore, we obtain the users' prior consent,
          except when it is not legally required. Consent is particularly not
          necessary if the storage and reading of information, including
          cookies, are strictly necessary to provide the telemedia service
          (i.e., our online offer) explicitly requested by the users. In
          general, cookies with functions that are necessary for the display and
          operability of the online offer, load balancing, security, storing of
          preferences, and selection options of the users, or similar purposes
          related to the provision of the main and ancillary functions of the
          online offer requested by the users, are considered to be strictly
          necessary. The revocable consent is communicated to the users clearly
          and contains information on the respective cookie use.
        </p>
        <p>
          <strong>Notes on Legal Bases for Data Protection: </strong>The legal
          basis on which we process personal data of users with the help of
          cookies depends on whether we ask the users for their consent. If the
          users consent, the legal basis for the processing of their data is the
          declared consent. Otherwise, the data processed with the help of
          cookies are processed on the basis of our legitimate interests (e.g.,
          in a business operation of our online offer and improvement of its
          usability) or, if the use of cookies is necessary to fulfill our
          contractual obligations, if the use of cookies is necessary to fulfill
          our contractual obligations. The purposes for which the cookies are
          processed by us are clarified in the course of this privacy policy or
          within our consent and processing processes.
        </p>
        <p>
          <strong>Storage Duration: </strong>Regarding the storage duration, the
          following types of cookies are distinguished:
        </p>
        <ul>
          <li>
            <strong>
              Temporary Cookies (also: Session or Session Cookies):
            </strong>
            Temporary cookies are deleted at the latest after a user leaves an
            online offer and closes their end device (e.g., browser or mobile
            application).
          </li>
          <li>
            <strong>Permanent Cookies:</strong> Permanent cookies remain stored
            even after the end device is closed. For example, the login status
            can be saved or preferred content can be displayed directly when the
            user visits a website again. The storage duration can be up to two
            years for permanent cookies.
          </li>
        </ul>
        <p>
          <strong>General Notes on Withdrawal and Objection (Opt-Out):</strong>
          Users can revoke consents given at any time and object to processing
          in accordance with statutory provisions. For example, users can
          restrict the use of cookies in their browser settings (which may also
          limit the functionality of our online offer). An objection to the use
          of cookies for online marketing purposes can also be declared via the
          websites
          <a href="https://optout.aboutads.info/" target="_new">
            https://optout.aboutads.info
          </a>
          and
          <a href="https://www.youronlinechoices.com/" target="_new">
            https://www.youronlinechoices.com/
          </a>
          .
        </p>
        <ul className="m-elements">
          <li className="">
            <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR). Consent (Article 6 (1) (a) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further Information on Processing Processes, Procedures, and
            Services:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processing of Cookie Data Based on Consent:</strong>
            We use a consent management solution, in which the consent of the
            users to the use of cookies, or the procedures and providers named
            within the scope of the consent management solution, is obtained.
            This procedure serves to obtain, log, manage, and revoke consents,
            particularly with regard to the use of cookies and comparable
            technologies that are used to store, read, and process information
            on the users' end devices. Within the scope of this procedure, the
            users' consents to the use of cookies and the associated processing
            of information, including the specific processing and providers
            mentioned in the consent management process, are obtained. Users
            also have the opportunity to manage and revoke their consents. The
            consent statements are stored to avoid repeated queries and to be
            able to prove the consent according to the legal requirements. The
            storage takes place server-side and/or in a cookie (so-called
            Opt-In-Cookie) or by means of comparable technologies to be able to
            assign the consent to a specific user or their device. In the
            absence of specific information on the providers of consent
            management services, the following general notes apply: The duration
            of the storage of the consent is up to two years. A pseudonymous
            user identifier is created and stored together with the time of the
            consent, the information on the scope of the consent (e.g.,
            categories of cookies and/or service providers), as well as details
            about the browser, the system, and the used end device;
            <span className="">
              <strong>Legal Bases:</strong> Consent (Article 6 (1) (a) GDPR).
            </span>
          </li>
        </ul>
        <h2 id="m136">Presence in Social Networks (Social Media)</h2>
        <p>
          We maintain online presences within social networks and process users'
          data in this context to communicate with the users active there or to
          offer information about us.
        </p>
        <p>
          We would like to point out that users' data may be processed outside
          the European Union. This may pose risks to users because, for example,
          the enforcement of users' rights could be made more difficult.
        </p>
        <p>
          Furthermore, users' data within social networks are usually processed
          for market research and advertising purposes. For example, user
          profiles can be created based on the users' behavior and the resulting
          interests of the users. The usage profiles can in turn be used, for
          example, to place advertisements inside and outside the networks that
          presumably correspond to the interests of the users. For these
          purposes, cookies are usually stored on the users' computers, in which
          the users' behavior and interests are stored. Furthermore, data can
          also be stored in the user profiles independently of the devices used
          by the users (especially if the users are members of the respective
          platforms and are logged in to them).
        </p>
        <p>
          For a detailed presentation of the respective processing forms and the
          opt-out options, we refer to the data protection declarations and
          information provided by the operators of the respective networks.
        </p>
        <p>
          Also, in the case of requests for information and the assertion of
          data subject rights, we note that these can be most effectively
          asserted with the providers. Only the providers have access to the
          users' data and can directly take appropriate measures and provide
          information. Should you still need assistance, you can contact us.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Types of Data Processed:</strong> Contact data (e.g., email,
            phone numbers); Content data (e.g., entries in online forms); Usage
            data (e.g., visited websites, interest in content, access times).
            Meta/communication data (e.g., IP addresses, time stamps,
            identification numbers, consent status).
          </li>
          <li>
            <strong>Affected Persons:</strong> Users (e.g., website visitors,
            users of online services).
          </li>
          <li>
            <strong>Purposes of Processing:</strong> Contact requests and
            communication; Feedback (e.g., collecting feedback via online form).
            Marketing.
          </li>
          <li className="">
            <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further Information on Processing Processes, Procedures, and
            Services:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>LinkedIn: </strong>Social network;{" "}
            <strong>Service provider:</strong> LinkedIn Ireland Unlimited
            Company, Wilton Place, Dublin 2, Ireland;
            <span className="">
              <strong>Legal Bases:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);
            </span>
            <strong>Website:</strong>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com
            </a>
            ;<strong>Privacy Policy:</strong>
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ;<strong>Grounds for Data Transfer to Third Countries:</strong> Data
            Privacy Framework (DPF); <strong>Opt-Out Option:</strong>
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            . <strong>Further Information:</strong> We, together with LinkedIn
            Ireland Unlimited Company, are jointly responsible for the
            collection (but not further processing) of data of visitors for the
            purpose of creating "Page Insights" (statistics) of our LinkedIn
            profiles. This data includes information on the types of content
            users view or interact with, or the actions they take, as well as
            information about the devices used by the users (e.g., IP addresses,
            operating system, browser type, language settings, cookie data) and
            information from users' profiles, such as job function, country,
            industry, hierarchical level, company size, and employment status.
            Privacy information on the processing of users' data by LinkedIn can
            be found in LinkedIn's privacy notices:
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            <br />
            We have entered into a special agreement with LinkedIn Ireland
            ("Page Insights Joint Controller Addendum (the 'Addendum')",
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), which specifically regulates the security measures LinkedIn must
            observe and in which LinkedIn has agreed to fulfill the rights of
            the data subjects (i.e., users can, for example, send requests for
            information or deletion directly to LinkedIn). The rights of the
            users (in particular to access, deletion, objection, and complaint
            to the competent supervisory authority) are not restricted by the
            agreements with LinkedIn. The joint responsibility is limited to the
            collection of data by and the transmission to LinkedIn Ireland
            Unlimited Company, a company based in the EU. The further processing
            of the data is the sole responsibility of LinkedIn Ireland Unlimited
            Company, in particular, the transfer of the data to the parent
            company LinkedIn Corporation in the USA.
          </li>
        </ul>
        <h2 id="m15">Modification and Update of the Privacy Policy</h2>
        <p>
          We ask you to regularly inform yourself about the content of our
          privacy policy. We adapt the privacy policy as soon as the changes in
          the data processing carried out by us make this necessary. We will
          inform you as soon as the changes require your participation (e.g.,
          consent) or other individual notification.
        </p>
        <p>
          If we provide addresses and contact information of companies and
          organizations in this privacy policy, please note that the addresses
          may change over time and please check the information before
          contacting us.
        </p>
        <h2 id="m42">Definitions</h2>
        <p>
          In this section, you will find an overview of the terms used in this
          privacy policy. To the extent that the terms are legally defined, the
          statutory definitions shall apply. The following explanations, on the
          other hand, are intended primarily for the purpose of comprehension.
        </p>
        <ul className="glossary">
          <li>
            <strong>Personal Data:</strong> "Personal data" means any
            information relating to an identified or identifiable natural person
            (hereinafter "data subject"); an identifiable natural person is one
            who can be identified, directly or indirectly, in particular by
            reference to an identifier such as a name, an identification number,
            location data, an online identifier (e.g., cookie), or to one or
            more factors specific to the physical, physiological, genetic,
            mental, economic, cultural, or social identity of that natural
            person.
          </li>
          <li>
            <strong>Controller:</strong> The "controller" is the natural or
            legal person, public authority, agency, or other body that, alone or
            jointly with others, determines the purposes and means of the
            processing of personal data.
          </li>
          <li>
            <strong>Processing:</strong> "Processing" is any operation or set of
            operations performed on personal data or on sets of personal data,
            whether or not by automated means. The term is broad and includes
            virtually any handling of data, whether it be collecting,
            evaluating, storing, transmitting, or deleting.
          </li>
        </ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Legal text by Dr. Schwenke - for further information, please click."
            target="_blank"
            rel="noreferrer noopener"
          >
            Created with the free Datenschutz-Generator.de by Dr. Thomas
            Schwenke
          </a>
        </p>
      </>
    </div>
  );
};

export default PrivacyEN;
