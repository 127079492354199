import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import style from "./Experience.module.scss";
import Heading from "../../modules/Heading/Heading";
import { useTranslation } from "react-i18next";
import { WorkOutline } from "@styled-icons/material-outlined/WorkOutline";

const Experience = () => {
  const { t } = useTranslation();

  // Laden der Erfahrungsdaten aus den Lokalisierungsdateien
  const experiences = t("experience.positions", { returnObjects: true });

  return (
    <main className={style.experience} id="experience">
      <Heading heading={t("experience.title")} />
      <VerticalTimeline>
        {(experiences as Array<any>).map((exp, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{ background: "hsl(256, 25%, 29%)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date={exp.date}
            iconStyle={{ background: "rgb(34, 34, 85)", color: "#fff" }}
            icon={<WorkOutline />}
          >
            {exp.additionalExperience ? (
              <p>{exp.additionalExperience}</p>
            ) : (
              <>
                <h3
                  className="vertical-timeline-element-title"
                  style={{ fontSize: "1.8rem" }}
                >
                  {t("experience.position")}: {exp.position}
                </h3>
                <h4
                  className="vertical-timeline-element-subtitle"
                  style={{ fontSize: "1.6rem" }}
                >
                  {t("experience.project")}: {exp.project}
                </h4>
                <h5 className="vertical-timeline-element-subtitle">
                  {t("experience.client")}: {exp.client}
                </h5>
                <p>{t("experience.tasks")}:</p>
                <ul>
                  {exp.tasks?.map(
                    (
                      task:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | Iterable<React.ReactNode>
                        | null
                        | undefined,
                      taskIndex: React.Key | null | undefined
                    ) => (
                      <li key={taskIndex}>{task}</li>
                    )
                  )}
                </ul>
                <p>
                  {t("experience.technologies")}: {exp.technologies}
                </p>
              </>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </main>
  );
};

export default Experience;
