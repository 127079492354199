import React, { useEffect, useState } from "react";
import style from "./Header.module.scss";
import Navbar from "./Navbar/Navbar";
import { useLocation } from "react-router-dom";
import Button from "../../modules/Button/Button";
import { Language } from "@styled-icons/fa-solid/Language";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const location = useLocation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const handleScroll = () => {
    const sections = [
      "home",
      "about",
      "services",
      "skills",
      "experience",
      "contact",
    ];
    for (const section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const bounding = element.getBoundingClientRect();
        if (bounding.top >= 0 && bounding.top <= window.innerHeight / 2) {
          setActiveSection(section);
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const shouldDisplayMenuButtons =
    location.pathname !== "/impressum" &&
    location.pathname !== "/privacy-policy";

  return (
    <header className={style.header}>
      <nav
        className={style.navbar}
        style={{ backgroundColor: colorChange ? "#1a1919" : "transparent" }}
      >
        <div className={style.containerNav}>
          <a href="/" className={style.navLogo}>
            <img
              src="/three.png"
              alt="Logo"
              style={{ width: "50px", height: "50px" }}
            />
          </a>
          <div className={style.navButton}>
            <Button type="language">
              <>
                <Language
                  size={20}
                  style={{ marginRight: "10px", marginBottom: "3px" }}
                />
                English version
              </>
            </Button>
          </div>
        </div>
        {shouldDisplayMenuButtons && (
          <>
            <ul className={`${style.navMenu} ${isActive ? style.active : ""}`}>
              <Navbar activeSection={activeSection} />
            </ul>
            <div className={style.hamburger} onClick={toggleMenu}>
              <span className={style.bar}></span>
              <span className={style.bar}></span>
              <span className={style.bar}></span>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
